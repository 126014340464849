<template>
    <el-container class="content">
      <el-header class="header">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        编辑面诊
      </el-header>
      <el-main>
        <div class="top_user">
            <div class="title">
                <p style="margin-bottom: 10px;margin-left: 10px;margin-top: 10px;padding-top: 10px;">用户信息</p>
            </div>
            <div>
                <div class="order-box">
                    <div class="order-vip cursor" @click="orderChangeTeacherShow=true">
                        <div style="display: flex;align-items: center;">
                            <img :src="$getimgsrc(orderTeacherName.avatar)" alt="" v-if="orderTeacherName.avatar" style="border-radius: 50%;">
                            <img src="@/assets/images/avatar.png" alt="" v-else>
                            <span class="name" v-if="orderTeacherName.realname">{{ orderTeacherName.realname }}</span>
                            
                            <span class="name" v-else>点击选择会员</span>
                        </div>
                        <b class="iconfont icon-jia"></b>
                    </div>
                </div>
            </div>
            <el-form ref="user" :rules="userrules" :model="problem" label-width="80px">
                <el-form-item label="用户名" prop="name">
                    <el-input v-model="problem.name" style="width: 200px;" disabled placeholder="用户名"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="mobile">
                    <el-input v-model="problem.mobile" style="width: 300px;" disabled placeholder="联系方式"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="top_user">
            <div class="title">
                <p style="margin-bottom: 10px;margin-left: 10px;margin-top: 10px;padding-top: 10px;">诊断内容</p>
            </div>
            <div class="formBox">
                <el-form ref="problem" :rules="rules" :model="problem" >
                    <el-form-item label="望:" prop="look">
                        <el-checkbox-group v-model="problem.look">
                            <el-checkbox :label="item.id" v-for="(item,index) in problemList[0]" :key="index">{{ item.name }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="闻:" prop="hope">
                        <el-checkbox-group v-model="problem.hope">
                            <el-checkbox :label="item.id" v-for="(item,index) in problemList[1]" :key="index">{{ item.name }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="问:" prop="ask">
                        <el-checkbox-group v-model="problem.ask">
                            <el-checkbox :label="item.id" v-for="(item,index) in problemList[2]" :key="index">{{ item.name }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="切:" prop="cut">
                        <el-checkbox-group v-model="problem.cut">
                            <el-checkbox :label="item.id" v-for="(item,index) in problemList[3]" :key="index">{{ item.name }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="有无过敏:">
                        <el-switch
                        v-model="problem.allergy"
                        :active-value="1"
                        :inactive-value="0">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="舌苔照片:" prop="fur_image">
                        <el-upload
                        class="avatar-uploader"
                        :action="this.$imgFile"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        >
                        <img v-if="problem.fur_image" :src="$getimgsrc(problem.fur_image)" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="诊断:" prop="diagnosis">
                        <el-input v-model="problem.diagnosis" type="textarea" rows="5" placeholder="诊断内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="top_user">
            <div class="title">
                <p style="margin-bottom: 10px;margin-left: 10px;margin-top: 10px;padding-top: 10px;">开方信息</p>
            </div>
            <div class="AddInfo" style="margin-left: 15px;">
                <div class="shopName" style="width: 120px;font-weight: 600;">
                    <span>商品名称：</span>
                </div>
                <div class="shopName" style="width: 80px;font-weight: 600;">
                    <span>数量：</span>
                </div>
                <div class="shopName" style="width: 80px;font-weight: 600;">
                    <span>单位：</span>
                </div>
                <div class="shopName" style="width: 80px;font-weight: 600;">
                    <span>单价：</span>
                </div>
                <div class="shopName" style="width: 160px;font-weight: 600;">
                    <span>服务人员：</span>
                </div>
                <div class="shopName" style="width: 200px;font-weight: 600;">
                    <span>穴位选择</span>
                </div>
            </div>
            <div class="AddInfo" v-for="(item,index) in problem.shop" :key="index">
                <div class="shopName">
                    <!-- <span>商品名称：</span> -->
                    <el-dropdown @command="handleCommand($event,index)">
                    <span class="el-dropdown-link">
                        <el-input v-model="problem.shop[index].name" placeholder="商品名称" style="width: 120px;" @input="getShopFuwu(item.name)"></el-input>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="item2.id" v-for="(item2,index2) in keyList" :key="index2">{{ item2.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-input v-model="problem.shop[index].name" placeholder="商品名称" style="width: 100px;"></el-input> -->
                </div>
                <div class="shopName">
                    <!-- <span>数量：</span> -->
                    <el-input v-model="problem.shop[index].sum"  placeholder="数量" style="width: 80px;"></el-input>
                </div>
                <div class="shopName">
                    <!-- <span>单位：</span> -->
                    <el-input v-model="problem.shop[index].danwei" placeholder="单位" disabled style="width: 80px;"></el-input>
                </div>
                <div class="shopName">
                    <!-- <span>单价：</span> -->
                    <el-input v-model="problem.shop[index].price" placeholder="单价" disabled style="width: 80px;"></el-input>
                </div>
                <div class="shopName">
                    <!-- <span>服务人员：</span> -->
                    <el-select
                        :disabled="item.type == 2"
                        style="width: 160px;"
                        v-model="problem.shop[index].person"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="服务人员">
                        <el-option
                        v-for="item in shifuList"
                        :key="item.id"
                        :label="item.realname"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="shopName">
                    <el-select
                        style="width: 200px;"
                        v-model="problem.shop[index].point"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="穴位选择">
                        <el-option
                        v-for="item in xueweiList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="but">
                    <el-button type="primary" @click="addShop" v-if="index == problem.shop.length-1">添加</el-button>
                    <el-button type="danger" @click="remList(index)" v-if="problem.shop.length > 1">移除</el-button>
                </div>
            </div>
            
        </div>
        <div class="top_user">
            <div class="title">
                <p style="margin-bottom: 10px;margin-left: 10px;margin-top: 10px;padding-top: 10px;">其他</p>
            </div>
            <div class="formBox">
                <el-form   label-width="100px">
                <el-form-item label="注意事项：" >
                    <el-input v-model="problem.take_care" type="textarea" rows="5"  placeholder="注意事项"></el-input>
                </el-form-item>
                <el-form-item label="备注信息：">
                    <el-input v-model="problem.remarks" type="textarea" rows="5"  placeholder="备注信息"></el-input>
                </el-form-item>
            </el-form>
            </div>
            
        </div>
        <el-footer style="text-align:center;margin-top: 20px;">
            <el-button type="primary" round @click="conpoment('problem')">提交订单</el-button>
        </el-footer>
      </el-main>
      
      <!-- 提交订单 -->
    <el-dialog class="dialog" center :visible.sync="infoshow" width="80%" :before-close="handleClose"
    append-to-body>
        <template slot="title">
            <div class="dia-title">确认信息</div>
        </template>
        <template>
            <el-container style="height:370px;">
            <el-main class="cell-list">
                <div class="top_user" style="width:900px;">
                    <div class="title">
                        <p style="margin-bottom: 10px;margin-left: 10px;margin-top: 10px;padding-top: 10px;">开方信息</p>
                    </div>
                    <div class="title">
                        <p style="margin-bottom: 10px;margin-left: 10px;margin-top: 10px;padding-top: 10px;">确认签名</p>
                    </div>
                    <signature @image="imgUrl"></signature>
                    <p>订单金额：<span style="color:red;">{{ allmoney }}</span></p>
                    <div class="AddInfo" v-for="(item,index) in problem.shop" :key="index">
                        <div class="shopName">
                            <span>商品名称：</span>
                            <el-dropdown @command="handleCommand($event,index)">
                            <span class="el-dropdown-link">
                                <el-input v-model="problem.shop[index].name" placeholder="商品名称" disabled style="width: 100px;" @input="getShopFuwu(item.name)"></el-input>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="item2.id" v-for="(item2,index2) in keyList" :key="index2">{{ item2.name }}</el-dropdown-item>
                            </el-dropdown-menu>
                            </el-dropdown>
                            <!-- <el-input v-model="problem.shop[index].name" placeholder="商品名称" style="width: 100px;"></el-input> -->
                        </div>
                        <div class="shopName">
                            <span>数量：</span>
                            <el-input v-model="problem.shop[index].sum"  placeholder="数量" disabled style="width: 80px;"></el-input>
                        </div>
                        <div class="shopName">
                            <span>单位：</span>
                            <el-input v-model="problem.shop[index].danwei" placeholder="单位" disabled style="width: 80px;"></el-input>
                        </div>
                        <div class="shopName">
                            <span>单价：</span>
                            <el-input v-model="problem.shop[index].price" placeholder="单价" disabled style="width: 80px;"></el-input>
                        </div>
                    </div>
                    
                    <div style="margin-top:20px;">
                        <el-button type="primary" @click="postThis">提交订单</el-button>
                    </div>
                </div>
            </el-main>
            </el-container>
        </template>
    </el-dialog>
    <!-- 选择用户 -->
    <el-dialog class="dialog" center :visible.sync="orderChangeTeacherShow" width="50%" :before-close="handleClose"
        append-to-body>
        <template slot="title">
            <div class="dia-title">选择用户</div>
        </template>
        <template >
            <!-- <div class="alert el-icon-warning">
            合理的排班会让工作更便捷、高效哦~
            </div> -->
            <el-container style="height:370px;">
            <el-main class="cell-list">
                <el-radio-group v-model="orderCellRadio">
                <el-radio  :label="i" v-for="(item,i) in userList" :key="i" @change="orderChangeTeacher(item)">
                    <!-- <div class="disable" v-if="!i">
                    占用
                    </div> -->
                    <div class="title">
                    <img :src="$getimgsrc(item.avatar)" alt="" v-if="item.avatar" style="border-radius: 50%;">
                    <img src="@/assets/images/avatar.png" alt="" v-else>
                    {{ item.realname }}
                    </div>
                </el-radio>
                </el-radio-group>
            </el-main>
            </el-container>
        </template>
    </el-dialog>
    </el-container>
  </template>
  
  <script>
  import signature from '@/module/signature'
    export default {
      data() {
        return {
            infoshow:false,
            orderChangeTeacherShow: false,
            problem:{
                user_id:null,
                name:'',
                mobile:'',
                look:[],//望
                hope:[],//闻
                ask:[],//问
                cut:[],//切
                sign:'',//签名
                take_care:'',//注意事项
                remarks:'',//备注
                diagnosis:'',//诊断
                fur_image: '',//舌苔
                allergy:0,//1有过敏0无
                shop:[],//商品信息
            },
            userrules:{
                name:[
                    { required: true, message: '请选择就诊人员', trigger: 'blur' }
                ],
                mobile:[
                    { required: true, message: '请选择就诊人员', trigger: 'blur'  }
                ],
            },
            rules:{
                
                look:[
                    { type: 'array', required: true, message: '请至少选择一个', trigger: 'change' }
                ],
                hope:[
                    { type: 'array', required: true, message: '请至少选择一个', trigger: 'change' }
                ],
                ask:[
                    { type: 'array', required: true, message: '请至少选择一个', trigger: 'change' }
                ],
                cut:[
                    { type: 'array', required: true, message: '请至少选择一个', trigger: 'change' }
                ],
                fur_image:[
                    { required: true, message: '请上传舌苔照片', trigger: 'blur'  }
                ],
                diagnosis:[
                    { required: true, message: '请输入诊断内容', trigger: 'blur'  }
                ],
            },
            orderCellRadio:{},
            orderTeacherName:'',
            problemList:[],//问题列表
            shopinfo:{
                name:'',
                sid:'',
                sum:'',
                danwei:'',
                type:'',
                price:'',
                point:[],
                person:[],
            },//创建商品数组
            shaopNum:'1',
            //商品下拉框
            keyList:[],
            //师傅数据
            shifuList:[],
            xueweiList:[],
            userList:[],
            allmoney:0,
            id:null,//单子id
            Info:{},//全部数据
        }
      },
      components:{
        signature,
      },
      watch:{
        'problem.look'(newVal,oldVal){
            console.log(newVal);
            let fastarry  = []
            let faststrigy = ''
            fastarry = [...newVal,...this.problem.ask,...this.problem.hope,...this.problem.cut]//望，问，闻，切
            faststrigy = fastarry.join()
            this.$http.post('api/store/face/kuaijiezhenduan',{
                shuxing:faststrigy
            }).then(res =>{
                this.problem.diagnosis = res.data.data
                // console.log(res);
            })
        },
        'problem.hope'(newVal,oldVal){
            console.log(newVal);
            let fastarry  = []
            let faststrigy = ''
            fastarry = [...this.problem.look,...this.problem.ask,...newVal,...this.problem.cut]
            faststrigy = fastarry.join()
            this.$http.post('api/store/face/kuaijiezhenduan',{
                shuxing:faststrigy
            }).then(res =>{
                this.problem.diagnosis = res.data.data
                // console.log(res);
            })
        },
        'problem.ask'(newVal,oldVal){
            console.log(newVal);
            let fastarry  = []
            let faststrigy = ''
            fastarry = [...this.problem.look,...newVal,...this.problem.hope,...this.problem.cut]
            faststrigy = fastarry.join()
            this.$http.post('api/store/face/kuaijiezhenduan',{
                shuxing:faststrigy
            }).then(res =>{
                this.problem.diagnosis = res.data.data
                // console.log(res);
            })
        },
        'problem.cut'(newVal,oldVal){
            console.log(newVal);
            let fastarry  = []
            let faststrigy = ''
            fastarry = [...this.problem.look,...this.problem.ask,...this.problem.hope,...newVal]
            faststrigy = fastarry.join()
            this.$http.post('api/store/face/kuaijiezhenduan',{
                shuxing:faststrigy
            }).then(res =>{
                this.problem.diagnosis = res.data.data
                // console.log(res);
            })
        },
        // problem:{
        //     handler(newVal, oldVal) {
        //         // console.log(newVal);
        //         let fastarry  = []
        //         let faststrigy = ''
        //         fastarry = [...newVal.look,...newVal.ask,...newVal.hope,...newVal.cut]
        //         faststrigy = fastarry.join()
        //         this.$http.post('api/store/face/kuaijiezhenduan',{
        //             shuxing:faststrigy
        //         }).then(res =>{
        //             this.problem.diagnosis = res.data.data
        //             console.log(res);
        //         })
        //     },
        //     // deep: true
        // }
      },
      created(){
       
      },
      mounted(){
        this.id = this.$route.query.id
        this.getShifuList()
        this.getuserList()
        this.getxueweiList()
        this.getProblem()
      },
      methods: {
        
        //获取详情信息
        getInfo(){
            this.$http.post('api/store/face/faceorderdetail',{
                id:this.id
            }).then(res =>{
                let data = res.data.data
                this.Info = res.data.data
                this.problem.user_id = data.user_id
                this.problem.sign = data.sign
                this.problem.name = data.user.realname
                this.problem.mobile = data.user.mobile
                this.problem.look = data.look.map(Number)
                this.problem.hope = data.hope.map(Number)
                this.problem.ask = data.ask.map(Number)
                this.problem.cut = data.cut.map(Number)
                this.problem.take_care = data.take_care
                this.problem.remarks = data.remarks
                this.problem.allergy = data.allergy
                this.problem.diagnosis = data.diagnosis
                this.problem.fur_image = data.fur_image
                
                this.Info.shops.forEach((item,index)=>{
                    let obj = {
                        name:'',
                        sid:'',
                        sum:'',
                        danwei:'',
                        type:'',
                        price:'',
                        point:[],
                        person:[],
                    }
                    // let arr = []
                    // let xuewei = []
                    // console.log('服务人员',item.manystoreperson_id);
                    // for(let i in item.manystoreperson_id){
                    //     arr.push(Number(item.manystoreperson_id[i]))
                    // }
                    // for(let k in item.point){
                    //     xuewei.push(Number(item.point[k]))
                    // }
                    obj.name = item.shopname
                    obj.sid = item.sid
                    obj.sum = item.num
                    obj.danwei = item.danwei
                    obj.type = item.type
                    obj.price = item.unit_price
                    obj.person = item.manystoreperson_id.map(Number)
                    obj.point = item.point.map(Number)
                    this.problem.shop.push(obj)
                })
                
                // this.problem.shop = data.shops
            })
        },
        //关闭弹框
        handleClose() {
            this.orderChangeTeacherShow = false
            this.infoshow = false
        },
        //选择用户
        orderChangeTeacher(item) {
            this.orderTeacherName = item
            this.problem.user_id = item.id
            this.problem.name = item.realname
            this.problem.mobile = item.mobile
            this.orderChangeTeacherShow = false
        },
        //提交订单
        postThis(){
            this.$http.post('api/store/face/addfaceorder',this.problem).then(res =>{
                this.$message.success(res.data.msg)
                setTimeout(() => {
                    this.$router.go(-1)
                }, 1000);
            })
        },
        //获取签名
        imgUrl(url){
            // console.log('签名信息',url);
            const formData = new FormData();
            formData.append("file", url);
            this.$http.post('api/common/upload',formData).then(res =>{
                console.log('上传',res.data.data.url);
                this.problem.sign = res.data.data.url
            })
        },
        //计算价格
        conpoment(formName){
            console.log(formName);
            this.$refs['user'].validate((valid) => {
                if (valid) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let money = 0
                            for(let k in this.problem.shop){
                                money += Number(this.problem.shop[k].price)*Number(this.problem.shop[k].sum)
                            }
                            this.allmoney = money.toFixed(2)
                            this.infoshow = true
                        } else {
                            this.$message.error('请检查表单信息')
                            return false;
                        }
                    });
                } else {
                    this.$message.error('请检查表单信息')
                    return false;
                }
            });
            
        },
        //用户列表
        async getuserList(){
            await this.$http.post('api/store/storeuser/userlist',{
            page:this.page
            }).then(res =>{
            this.userList = res.data.data
            })
        },
        //移除添加列表
        remList(index){
            this.problem.shop.splice(index,1)
        },
        //获取穴位
        async getxueweiList(){
            await this.$http.post('api/store/face/pointlist').then(res =>{
                // console.log(res);
                this.xueweiList = res.data.data
            })
        },
        //获取服务人员
        async getShifuList(){
            await this.$http.post('api/store/index/shifu',{
                type:1
            }).then( res =>{
                // console.log(res);
                this.shifuList = res.data.data
            })
        },
        //获取商品服务
        getShopFuwu(item){
            this.$http.post('api/store/face/serverall',{
                keyword:item
            }).then(res =>{
                // console.log(res);
                this.keyList = res.data.data
            })
        },
        //菜单事件
        handleCommand(command,index) {
            console.log(command,index);
            this.problem.shop[index].sid = command
            for(let i in this.keyList){
                if(this.keyList[i].id == command){
                    this.problem.shop[index].type = this.keyList[i].type
                    this.problem.shop[index].name = this.keyList[i].name
                    this.problem.shop[index].danwei = this.keyList[i].shopunit_id
                    this.problem.shop[index].price = this.keyList[i].vip_price
                }
            }
            // this.$message('click on item ' + command);
        },
        //添加商品
        addShop(){
            this.problem.shop.push(this.shopinfo)
            this.shaopNum++
            this.shopinfo = {
                name:'',
                num:'',
                unit:'',
                price:'',
                personnel:'',
            }
        },
        //上传图片
        handleAvatarSuccess(res, file) {
            this.problem.fur_image = res.data.url;
        },
       //望闻问切属性
       getProblem(){
        this.$http.post('api/store/face/ficedia').then(res =>{
            this.problemList = res.data.data
            this.getInfo()
        })
       },
        // 返回上级
        returnOn() {
          this.$router.go(-1)
        },
        
  
      }
    };
  
  </script>
  <style>
    body{
        background-color: #f9f9f9;
    }
  </style>
  <style lang="less" scoped>
    .content{
        height: (100vh);
    }
    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
  
      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }
  .top_user{
    width: 1080px;
    padding-bottom: 10px;
    margin: auto;
    background-color: #ffffff;
	border-radius: 15px;
    .title{
        font-size: 20px;
        border-bottom: 1px solid #f4f4f4;
    }
    .AddInfo{
        margin-left: 10px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        .shopName{
            display: flex;
            align-items: center;
            margin-right: 20px;
        }
    }
    .formBox{
        margin-top: 10px;
        margin-left: 10px;
        .avatar-uploader .el-upload {
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            border: 1px dashed #d9d9d9;
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
    .order-box {
    padding: 0 10px 10px;
    box-sizing: border-box;
    background-color: #fff;

    .order-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-btn {
        width: 70%;
        line-height: 60px;

        span {
          width: 20%;
          font-size: 12px;
          padding: 5px 5px;
          color: #409eff;
          border: 1px solid #409eff;
          border-radius: 5px;
          margin-left: 10px;
        }
      }

      .right {
        position: relative;
        right: 30px;
        color: #409eff;

        span {
          position: absolute;
          top: 5px;
          right: -15px;
          width: 22px;
          height: 22px;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          color: #fff;
          background-color: #f56c6c;
          border-radius: 100%;
        }
      }
    }

    .order-vip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      overflow: hidden;
      padding-right: 10px;
      margin-top: 10px;
      img {
        float: left;
        width: 40px;
        height: 40px;
        margin: 10px 15px;
      }

      .name {
        float: left;
        color: #999;
      }

      b {
        float: right;
        color: #ccc;
        font-size: 20px;
        text-align: center;
        font-weight: normal;
      }
    }
  }
  }
  .dialog {
    /deep/ .el-dialog__body {
      height: 400px;
    }

    .alert {
      color: #ff9900;
      margin-bottom: 20px;
    }

    .cell-list {
      width: 100%;

      /deep/ .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 0;
          position: relative;
          overflow: hidden;

          .disable {
            position: absolute;
            top: 0;
            right: -37px;
            font-size: 12px;
            transform: rotate(45deg);
            background-color: #f95e5a;
            color: #fff;
            width: 100px;
            line-height: 24px;
            text-align: center;
          }

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
  </style>
  